import React from 'react'
import { isMobile } from "../../isMobile"
import Page from '../../components/page'
import { InfoNote, WarningNote, SuccessNote } from '../../components/highlight'
import { Link } from 'gatsby'
import withRoot from '../../withRoot'
import { withStyles } from '@material-ui/core/styles'
import {
  Grid,
  Typography,
} from '@material-ui/core'
import clsx from 'clsx'

import install01 from '../../static/fitbit-install/01.jpg'
import install02 from '../../static/fitbit-install/02.jpg'
import install03 from '../../static/fitbit-install/03.jpg'
import install04 from '../../static/fitbit-install/04.jpg'
import install05 from '../../static/fitbit-install/05.jpg'

const styles = theme => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.light,
    '&:hover': {
      color: theme.palette.secondary.light,
    },
  },
  parag: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  col: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '45%',
  },
  img: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: '5px',
  }
})

@withRoot
@withStyles(styles)
export default class Unlock extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <Page toggleTheme={this.props.toggleTheme} title="How to unlock a face from the Fitbit gallery?">
        <Typography className={classes.parag}>
          Great, you found a beautiful clockface on the <strong>Fitbit Gallery</strong> that was made with FitFace.
        </Typography>
        <Typography className={classes.parag}>
          You will have a 1 hour trial to decide whether you want to buy it.<br />
          At the end of the trial, a lock screen with a code will appear.
        </Typography>
        <InfoNote>If you don’t like it or simply don’t want to buy it, just install another clockface from the Fitbit gallery.</InfoNote>
        <Typography className={classes.parag}>If you want to buy it, you have 3 choices:</Typography>
        <ul>
          <li><Typography>Use the QR-code displayed on the screen which will take you directly to the buying page. No need to enter a code anywhere.</Typography></li>
          <li>
            <Typography>Or go to <a className={classes.link} href="https://fitface.xyz/buy/">https://fitface.xyz/buy/</a></Typography>
            <Typography>Enter the code displayed on the watch. </Typography>
            <Typography>For example use <b>BUDA0G</b> and you’ll get the page of the Overlap clockface.</Typography>
          </li>
          <li><Typography>Or manually look for the clockface in FitFace gallery...</Typography></li>
        </ul>
        <Typography className={classes.parag}>
          Then add the clockface to the cart. You can continue shopping if you want to buy more than one clockface.
        </Typography>
        <Typography className={classes.parag}>
          Once you are done, proceed to checkout.
        </Typography>
        <Typography className={classes.parag}>
          You successfully bought your first clockface.
          Congratulation. You can view all your purchases on FitFace website <a className={classes.link} href="https://fitface.xyz/collection/purchases">here</a>.
        </Typography>
        <WarningNote>Your clockface will still display the code. You need to unlock it.</WarningNote>
        <Typography className={classes.parag}>You only need to unlock a clockface if you installed it from Fitbit gallery.</Typography>
        <SuccessNote>
          We recommend to use our generic FitFace clockface <a className={classes.link} href="https://gallery.fitbit.com/details/0799c9f7-8aa4-4795-9af2-8f8087654321">here</a>.<br />
          You will be able to install and manage all your FitFace clockfaces easily.
          Follow step by step instructions <Link className={classes.link} to="/user/getstarted">here</Link>
        </SuccessNote>

        <br />
        <Typography id="complete" className={classes.parag} variant="h5" color="secondary">To complete the unlock procedure:</Typography>
        <Typography className={classes.parag}>
          On your mobile phone, open the Fitbit app and go to the clockface settings.
        </Typography>
        <Grid container direction={isMobile() ? "column" : "row"} justify={isMobile() ? "center" : "space-between"} alignItems="center">
          <Grid item className={clsx(!isMobile() && classes.col)}>
            <Typography className={classes.parag}>
              if you freshly installed the clockface you should have this screen :
            </Typography>
            <img src={install01} className={classes.img} />
          </Grid>
          <Grid item className={clsx(!isMobile() && classes.col)}>
            <Typography className={classes.parag}>
              Otherwise you can simply access the settings by clicking on the 3 horizontal dots, then click on the settings menu
            </Typography>
            <img src={install02} className={classes.img} />
          </Grid>
        </Grid>
        <Typography className={classes.parag}>Once you open the settings, you should have a screen like this:</Typography>
        <img src={install03} className={classes.img} />
        <Grid container direction={isMobile() ? "column" : "row"} justify={isMobile() ? "center" : "space-between"} alignItems="center">
          <Grid item className={clsx(!isMobile() && classes.col)}>
            <Typography className={classes.parag}>
              if you alread bought the clockface, click on ‘I have alreay bought this face’. You can enter your FitFace login/password to unlock the clockface
            </Typography>
            <img src={install04} className={classes.img} />
          </Grid>
          <Grid item className={clsx(!isMobile() && classes.col)}>
            <Typography className={classes.parag}>
              if you click on buy, you will have this screen. It contains all the info (link and code) mentionned earlier to buy the clockface. You can also enter your FitFace login/password to unlock the clockface once you bought it.
            </Typography>
            <img src={install05} className={classes.img} />
          </Grid>
        </Grid>
        <SuccessNote>
          Your clockface is now unlocked. Enjoy!
        </SuccessNote>
      </Page>
    )
  }
}
